import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgCactus(props) {
  return (
    <SVGIcon viewBox="0 0 50 62.5" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M16.167 35.496h17.666l-.888 11.667H17.056l-.889-11.667zm-2.756-5.688H36.59v2.851H13.411v-2.851zm13.56-14.368l7.207-.575v-3.744c0-.728.326-1.318 1.099-1.315.748.003 1.19.59 1.19 1.315 0 1.323-.03 2.657.035 3.978.045.874.09 1.748-.669 1.921l-8.863.877v9.073h-3.931l.08-5.569-8.349-2.45c-1.356-.629-1.265-1.805-1.265-3.117V10.73c0-.724.362-1.312 1.125-1.312.75 0 1.164.593 1.164 1.312v6.144l7.235 2.105V4.808c0-1.085.886-1.971 1.971-1.971a1.98 1.98 0 011.971 1.971V15.44zm8.317-8.459c-2.351-.015-3.948 1.799-3.948 4.141v1.125l-1.532.122V4.808A4.817 4.817 0 0025 0a4.822 4.822 0 00-4.808 4.808V15.21l-1.56-.455V10.73c0-2.353-1.665-4.131-4.001-4.138-2.349-.008-3.962 1.79-3.962 4.138v5.104c0 2.386.546 5.024 3.197 5.801l6.405 1.879-.051 3.456h-9.646v8.525h2.752L14.43 50h21.141l1.105-14.504h2.751v-8.525h-9.62v-6.512l6.374-.631c3.037-.432 3.28-2.285 3.147-4.872-.063-1.263-.025-2.567-.025-3.834.001-2.348-1.688-4.129-4.015-4.141z"
      />
    </SVGIcon>
  );
}

export default SvgCactus;
