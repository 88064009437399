import React from "react";
import styled from "styled-components";
import { useAnalytics, EventTypes } from "analytics";
import { Block, Fixed, Flex, LinkIcon, Paragraph, Stack } from "components";
import { Content } from "content";
import { Icon } from "icons";
import { PortfolioScrollRoutes } from "portfolio";
import { routes, RouterLink } from "router";
import { Skeleton } from "skeleton";
import { useDarkMode } from "theme";
import { focusOutlineStyles, MicroInteractionStyles } from "styles";

const BlurredBlock = styled(Block)`
  backdrop-filter: blur(1rem);
`;

const HEADER_HEIGHT_SMALL = "4rem";
const HEADER_HEIGHT_LARGE = "6rem";

const createHashLink = hash => ({
  pathname: routes.home,
  hash: `#${hash}`
});

const LogoLink = styled(Paragraph)`
  ${MicroInteractionStyles};
  ${focusOutlineStyles()};
`;

export const Header = () => {
  const { trackEvent } = useAnalytics();

  const trackNavigationEvent = label =>
    trackEvent(EventTypes.NAVIGATION, "Navigation Link", label);

  return (
    <Fixed top={0} left={0} right={0} zIndex={3} boxShadow="elevation3">
      <BlurredBlock
        bg="surfaceAlpha2"
        borderBottom="1px solid"
        borderColor="borderPrimary"
        py={[3, 4]}
        px={[3, 4, 5]}
        height={[HEADER_HEIGHT_SMALL, HEADER_HEIGHT_SMALL, HEADER_HEIGHT_LARGE]}
      >
        <Flex height="100%" justifyContent="space-between" alignItems="center">
          <LogoLink
            as={RouterLink}
            to={createHashLink(PortfolioScrollRoutes.About)}
            style={{ textTransform: "uppercase", textDecoration: "none" }}
            onClick={() => trackNavigationEvent("Title")}
            color="textPrimary"
            fontSize={[3, 4, 5]}
            fontWeight="light"
            fontFamily="title"
            letterSpacing={2}
          >
            <Skeleton>
              <Content id="contact.name" />
            </Skeleton>
          </LogoLink>

          <Stack direction="horizontal" gap={3} justifyContent="flex-end">
            {/* <Link
              as={RouterLink}
              to={createHashLink(PortfolioScrollRoutes.About)}
              variant={
                isHashActive(PortfolioScrollRoutes.About) ? "Flat" : "Cta"
              }
              fontFamily="secondary"
              onClick={() => trackNavigationEvent("About")}
            >
              <Skeleton>About</Skeleton>
            </Link>
            <Link
              as={RouterLink}
              to={createHashLink(PortfolioScrollRoutes.Work)}
              variant={
                isHashActive(PortfolioScrollRoutes.Work) ? "Flat" : "Cta"
              }
              fontFamily="secondary"
              onClick={() => trackNavigationEvent("Work")}
            >
              <Skeleton>Work</Skeleton>
            </Link> */}
            <DarkModeToggle />
          </Stack>
        </Flex>
      </BlurredBlock>
    </Fixed>
  );
};

const DarkModeToggle = () => {
  const { trackEvent } = useAnalytics();
  const [isDarkMode, toggle] = useDarkMode();

  const handleDarkModeToggle = () => {
    trackEvent(
      EventTypes.INTERACTION,
      "Toggle Dark Mode",
      (!isDarkMode).toString()
    );
    toggle();
  };

  return (
    <LinkIcon
      as="button"
      onClick={handleDarkModeToggle}
      style={{ textDecoration: "none" }}
    >
      <span role="img" aria-label="Toggle dark mode">
        <Icon size={4} color="textPrimary" name={isDarkMode ? "Sun" : "Moon"} />
      </span>
    </LinkIcon>
  );
};

export const HeaderOffset = ({ children, ...props }) => (
  <Block
    pt={[HEADER_HEIGHT_SMALL, HEADER_HEIGHT_SMALL, HEADER_HEIGHT_LARGE]}
    {...props}
  >
    {children}
  </Block>
);
