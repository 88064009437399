import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgCart(props) {
  return (
    <SVGIcon viewBox="0 0 50 62.5" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M31.942 15.526c0 4.982 4.046 9.029 9.029 9.029 4.986 0 9.03-4.043 9.03-9.029a9.03 9.03 0 00-18.059 0m9.028 6.105a6.1 6.1 0 01-6.105-6.105 6.105 6.105 0 016.105-6.105 6.101 6.101 0 016.106 6.105 6.105 6.105 0 01-6.106 6.105zm-9.021 1.467a11.77 11.77 0 01-1.801-2.924h-14.28v2.924h16.081zM19.415 36.426a2.076 2.076 0 11.001 4.153 2.076 2.076 0 01-.001-4.153zm12.273 2.076a2.076 2.076 0 014.152 0 2.07 2.07 0 01-2.076 2.077 2.075 2.075 0 01-2.076-2.077zM10.926 16.966l18.356.01a11.83 11.83 0 01.001-2.912l-19.137-.01-1.867-6.966H0v2.923h6.04l6.779 25.301h2.745a5 5 0 107.702 0h6.648a4.98 4.98 0 00-1.149 3.189c0 2.756 2.245 5 5 5 2.758 0 5-2.242 5-5a4.981 4.981 0 00-1.15-3.189h2.745l2.174-8.112a11.768 11.768 0 01-3.025.013l-1.387 5.175H15.059l-4.133-15.422zm25.39 9.318H16.982v2.923h19.334v-2.923zm3.352-6.375l5.834-7.066-2.054-1.701-4.162 5.04-1.282-1.03-1.669 2.076 3.333 2.681z"
      />
    </SVGIcon>
  );
}

export default SvgCart;
