import Uniqlo from "./uq.jpg";
import Hims from "./hims.webp";
import Hers from "./hers.webp";
import Roche from "./spol.jpg";
import SFN from "./fulfillment-warehouse.jpg";

export const Images = {
  Hers,
  Hims,
  Roche,
  SFN,
  Uniqlo
};
