import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgUniqloLogo(props) {
  return (
    <SVGIcon viewBox="0 0  446.3 127.5" width="1em" {...props}>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M154.4,70.9c-3.8-2.1-5.8-3.8-5.8-6.2c0-3.1,2.7-5,7-5c5,0,9.4,2.1,9.4,2.1l3.5-10.7
			c0,0-3.2-2.5-12.7-2.5c-13.2,0-22.4,7.6-22.4,18.2c0,6,4.3,10.6,10,13.9c4.6,2.6,6.2,4.5,6.2,7.2c0,2.9-2.3,5.2-6.6,5.2
			c-6.4,0-12.4-3.3-12.4-3.3l-3.7,10.7c0,0,5.6,3.7,14.9,3.7c13.6,0,23.3-6.7,23.3-18.7C165.3,78.9,160.3,74.3,154.4,70.9z"
          />
          <path
            fill="currentColor"
            d="M208.6,48.4c-6.7,0-11.9,3.2-16,8l-0.2-0.1l5.8-30.4H183l-14.7,77.3h15.1l5-26.4
			c2-10,7.1-16.1,11.9-16.1c3.4,0,4.7,2.3,4.7,5.6c0,2.1-0.2,4.6-0.7,6.7l-5.7,30.3h15.1l5.9-31.2c0.7-3.3,1.1-7.2,1.1-9.9
			C220.9,53.5,216.5,48.4,208.6,48.4z"
          />
          <path
            fill="currentColor"
            d="M255.1,48.4c-18.2,0-30.3,16.4-30.3,34.7c0,11.7,7.2,21.2,20.8,21.2c17.9,0,29.9-16,29.9-34.7
			C275.6,58.7,269.3,48.4,255.1,48.4z M247.7,92.6c-5.2,0-7.3-4.4-7.3-9.9c0-8.7,4.5-22.8,12.7-22.8c5.4,0,7.1,4.6,7.1,9.1
			C260.2,78.4,255.7,92.6,247.7,92.6z"
          />
          <path
            fill="currentColor"
            d="M314.3,48.4c-10.2,0-16,9-16,9h-0.2l0.9-8.1h-13.4c-0.7,5.5-1.9,13.8-3.1,20.1L272,124.6h15.1
			l4.2-22.4h0.3c0,0,3.1,2,8.9,2c17.8,0,29.4-18.2,29.4-36.6C329.9,57.5,325.4,48.4,314.3,48.4z M299.9,92.9c-3.9,0-6.2-2.2-6.2-2.2
			l2.5-14.1c1.8-9.4,6.7-15.7,11.9-15.7c4.6,0,6,4.3,6,8.3C314.1,78.9,308.3,92.9,299.9,92.9z"
          />
          <path
            fill="currentColor"
            d="M351.5,26.7c-4.8,0-8.7,3.8-8.7,8.8c0,4.5,2.8,7.6,7.1,7.6h0.2c4.7,0,8.8-3.2,8.9-8.8
			C359.1,29.8,356.1,26.7,351.5,26.7z"
          />
          <polygon
            fill="currentColor"
            points="330.3,103.2 345.5,103.2 355.8,49.6 340.5,49.6 		"
          />
          <path
            fill="currentColor"
            d="M394.2,49.5h-10.5l0.5-2.5c0.9-5.2,3.9-9.8,9-9.8c2.7,0,4.8,0.8,4.8,0.8l3-11.8
			c0,0-2.6-1.3-8.2-1.3c-5.4,0-10.7,1.5-14.8,5c-5.2,4.4-7.6,10.7-8.8,17.1l-0.4,2.5h-7l-2.2,11.4h7l-8,42.3h15.1l8-42.3h10.4
			L394.2,49.5z"
          />
          <path
            fill="currentColor"
            d="M430.6,49.6c0,0-9.5,23.8-13.7,36.8h-0.2c-0.3-4.2-3.7-36.8-3.7-36.8h-15.9l9.1,49.2
			c0.2,1.1,0.1,1.8-0.3,2.5c-1.8,3.4-4.7,6.7-8.2,9.1c-2.8,2.1-6,3.4-8.5,4.3l4.2,12.8c3.1-0.7,9.4-3.2,14.8-8.2
			c6.9-6.5,13.3-16.4,19.8-30l18.5-39.7H430.6z"
          />
        </g>
        <g>
          <path
            fill="currentColor"
            d="M74.8,14.8c0,0-1.4,0.4-3.7,1.1c-0.4-1.3-1-2.8-1.8-4.4c-2.6-5-6.5-7.7-11.1-7.7c0,0,0,0,0,0
			c-0.3,0-0.6,0-1,0.1c-0.1-0.2-0.3-0.3-0.4-0.5c-2-2.2-4.6-3.2-7.7-3.1c-6,0.2-12,4.5-16.8,12.2c-3.4,5.4-6,12.2-6.7,17.5
			c-6.9,2.1-11.7,3.6-11.8,3.7c-3.5,1.1-3.6,1.2-4,4.5c-0.3,2.5-9.5,72.9-9.5,72.9l75.6,13.1V14.7C75.3,14.7,75,14.8,74.8,14.8z
			 M57.3,20.2c-4,1.2-8.4,2.6-12.7,3.9c1.2-4.7,3.6-9.4,6.4-12.5c1.1-1.1,2.6-2.4,4.3-3.2C57,12,57.4,16.9,57.3,20.2z M49.1,4.4
			c1.4,0,2.6,0.3,3.6,0.9c-1.6,0.8-3.2,2.1-4.7,3.6c-3.8,4.1-6.7,10.5-7.9,16.6c-3.6,1.1-7.2,2.2-10.5,3.2
			C31.8,19.1,39.9,4.6,49.1,4.4z M37.5,59.4c0.4,6.4,17.3,7.8,18.3,22.9c0.7,11.9-6.3,20-16.4,20.6c-12.2,0.8-18.9-6.4-18.9-6.4
			l2.6-11c0,0,6.7,5.1,12.1,4.7c3.5-0.2,4.8-3.1,4.7-5.1c-0.5-8.4-14.3-7.9-15.2-21.7C23.9,51.8,31.5,40.1,48.3,39
			c6.5-0.4,9.8,1.2,9.8,1.2l-3.8,14.4c0,0-4.3-2-9.4-1.6C37.5,53.5,37.4,58.2,37.5,59.4z M61.3,19c0-3-0.4-7.3-1.8-10.9
			c4.6,0.9,6.8,6,7.8,9.1C65.5,17.7,63.5,18.3,61.3,19z"
          />
          <path
            fill="currentColor"
            d="M78.2,124l31.4-7.8c0,0-13.5-91.3-13.6-91.9c-0.1-0.6-0.6-1-1.1-1c-0.5,0-9.3-0.2-9.3-0.2s-5.4-5.2-7.4-7.2
			V124z"
          />
        </g>
      </g>
    </SVGIcon>
  );
}

export default SvgUniqloLogo;
