import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgMask(props) {
  return (
    <SVGIcon viewBox="0 0 50 62.5" width="1em" height="1em" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M31.447 12.023a62.012 62.012 0 004.555-3.613c1.982 1.736 3.656 3.756 4.777 5.915 1.711 3.298 2.109 6.853.446 10.226-2.431-4.661-8.857-7.81-13.381-10.128a58.225 58.225 0 003.603-2.4m-8.658 5.171l2.129-1.101 2.066 1.068c4.974 2.545 11.757 6.015 12.353 10.625-.39 4.788-2.28 8.865-4.669 12.036-3.16 4.194-7.105 6.771-9.587 7.302-2.77-.551-6.707-3.043-9.834-7.096-2.462-3.191-4.384-7.335-4.786-12.244.598-4.6 7.358-8.052 12.328-10.59zm2.13-14.321c2.939.492 6.04 1.843 8.805 3.761a58.332 58.332 0 01-3.929 3.093c-2.734 1.967-5.342 3.443-8.291 4.95-4.568 2.331-10.49 5.358-12.865 9.757-1.522-3.359-1.058-6.957.614-10.239 2.922-5.738 9.35-10.275 15.666-11.322zM21.495 28.05c-2.053.246-4.124.387-6.225.344 1.996 2.129 4.084 1.677 6.225-.344zm2.87.871c0-3.249-2.826-5.648-5.982-5.648s-5.983 2.4-5.983 5.648c0 3.249 2.827 5.648 5.983 5.648 3.155.001 5.982-2.398 5.982-5.648zm-5.982-3.143c1.775 0 3.477 1.277 3.477 3.144 0 1.866-1.702 3.143-3.477 3.143s-3.478-1.276-3.478-3.143c0-1.868 1.702-3.144 3.478-3.144zm9.723 3.143c0-1.868 1.701-3.144 3.477-3.144 1.776 0 3.477 1.277 3.477 3.144 0 1.866-1.702 3.143-3.477 3.143s-3.477-1.276-3.477-3.143zm3.478-5.648c-3.156 0-5.983 2.4-5.983 5.648 0 3.249 2.827 5.648 5.983 5.648 3.155 0 5.983-2.4 5.983-5.648-.001-3.248-2.828-5.648-5.983-5.648zm3.113 5.121c-2.102.043-4.172-.098-6.227-.344 2.143 2.021 4.23 2.473 6.227.344zm-4.078 12.802c-1.837-.273-3.241-2.228-5.501-.984-2.259-1.245-3.664.711-5.5.984 1.558 1.421 3.314 2.56 5.5 2.546 2.187.014 3.943-1.125 5.501-2.546zm6.535-35.528C33.648 2.799 29.418.689 24.918 0 17.477 1.149 10.167 6.151 6.724 12.91c-2.637 5.173-2.566 10.723.936 15.389.503 5.388 2.631 9.941 5.347 13.46 2.812 3.644 7.407 7.486 12.091 8.241 4.476-.75 9.201-4.977 11.833-8.47 2.61-3.465 4.688-7.902 5.197-13.117 3.641-4.687 3.884-10.148 1.168-15.385-1.43-2.754-3.601-5.28-6.142-7.36z"
      />
    </SVGIcon>
  );
}

export default SvgMask;
