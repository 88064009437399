import { Images } from "./assets";

export const meta = {
  title: "Portfolio",
  titleDecorator: "| Brendan Rygus",
  description: ""
};

export const contact = {
  name: "Brendan Rygus",
  email: "brendan.rygus@gmail.com",
  links: [
    {
      label: "Github",
      url: "https://github.com/brendanrygus"
    },
    {
      label: "LinkedIn",
      url: "https://www.linkedin.com/in/brendanrygus"
    },
    { label: "Instagram", url: "https://instagram.com/brendanrygus" }
  ]
};

const portfolio = {
  heroTitle: "UX Developer",
  heroSubtitle:
    "I create accessible, performant, and delightful user experiences.",
  title: "Recent Work",
  subtitle: ""
};

export const projects = [
  {
    id: "sfn",
    brand: "Shopify Fulfillment Network",
    title: "SFN",
    description:
      "Front-end platform and product engineer for Shopify's innovative shipping and fulfillment solution.",
    tags: ["React", "GraphQL", "TypeScript", "Design Systems"],
    timestamp: "Developed at Shopify, 2020-2021.",
    url: "https://shopify.com/fulfillment",
    imageSrc: Images.SFN,
    imageCaption: "Shopify Fulfillment Network",
    imageAlt: "Shopify fulfillment warehouse",
    brandColor: "#008060",
    icon: "Cart",
    logo: "Shopify"
  },
  {
    id: "uniqlo-jp",
    brand: "Uniqlo JP",
    title: "Unique Clothing",
    description:
      "On-site technical consultant and feature developer for the new Uniqlo flagship web store.",
    tags: ["React", "Redux", "TypeScript", "Design Systems"],
    timestamp: "Developed at Rangle Japan, 2019-2020.",
    url: "https://uniqlo.com/jp/ja",
    imageSrc: Images.Uniqlo,
    imageCaption: "Uniqlo Japan",
    imageAlt: "Shinjuku Uniqlo store",
    brandColor: "#FF0000",
    icon: "Cart",
    logo: "Uniqlo"
  },
  {
    id: "roche-spol",
    brand: "Roche Diabetes Care",
    title: "Diabetes Care Platform",
    description:
      "A personalized approach to diabetes care used by patients & professionals around the world.",
    tags: ["React", "Redux", "RxJs", "TypeScript", "styled-components"],
    timestamp: "Developed at Rangle.io, 2017-2018.",
    url: "https://www.diabetescareplatform.com/",
    imageSrc: Images.Roche,
    imageAlt: "",
    imageCaption: "Diabetes Care",
    // brandColor: "#0066CC",
    brandColor: "#4ABED4",
    icon: "Tube",
    logo: "Roche"
  },
  {
    id: "hims",
    brand: "For Hims",
    title: "Men's Health",
    description:
      "Rebuilding the front end of a controversial startup on the road to 'unicorn'-level growth.",
    tags: ["React", "Redux", "styled-components", "Component Library"],
    timestamp: "Developed at Rangle.io, 2019.",
    url: "https://forhims.com",
    imageSrc: Images.Hims,
    imageCaption: "For Hims",
    imageAlt: "",
    brandColor: "#efb27b",
    icon: "Cactus",
    logo: "Hims"
  }
];

const errors = {
  errorFallback: "Bummer, something went wrong.",
  pageNotFound: "Bummer, this page no longer exists.",
  returnToTop: "Go home."
};

export default {
  contact,
  errors,
  meta,
  projects,
  portfolio
};
