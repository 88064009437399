import React from "react";
import { createGlobalStyle } from "styled-components";
import {
  BasisBold,
  BasisMedium,
  BasisMono,
  BasisMonoBold,
  BasisRegular,
  EditorLight
} from "styles/fonts";

const TypographyGlobalStyles = createGlobalStyle`
 @font-face {
    font-family: 'EditorLight';
    src: url(${EditorLight});
    font-display: fallback;
  }
  @font-face {
    font-family: 'Basis';
    src: url(${BasisRegular});
    font-weight: 400;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Basis';
    src: url(${BasisMedium});
    font-weight: 500;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Basis';
    src: url(${BasisBold});
    font-weight: 700;
    font-display: fallback;
  }
  @font-face {
    font-family: 'BasisMono';
    src: url(${BasisMono});
    font-weight: 400;
    font-display: fallback;
  }
@font-face {
    font-family: 'BasisMono';
    src: url(${BasisMonoBold});
    font-weight: 700;
    font-display: fallback;
  }
`;

export const Typography = React.memo(() => <TypographyGlobalStyles />);
