import { useCallback, useState } from "react";

export function useLocalStorage(key, defaultValue) {
  const getValue = useCallback(
    defaultValue => {
      try {
        const valueFromLocalStorage = window.localStorage.getItem(key);
        if (valueFromLocalStorage) {
          return JSON.parse(valueFromLocalStorage);
        }
      } catch (error) {}
      return defaultValue;
    },
    [key]
  );
  const [value, setValue] = useState(() => getValue(defaultValue));

  const removeValue = useCallback(() => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
    } finally {
      setValue(defaultValue);
    }
  }, [key, defaultValue]);

  const updateValue = useCallback(
    value => {
      try {
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
      } finally {
        setValue(value);
      }
    },
    [key]
  );

  return {
    removeValue,
    updateValue,
    value
  };
}
