import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgUniqloLogo(props) {
  return (
    <SVGIcon viewBox="0 0  560 400" width="1em" {...props}>
      <path
        d="m145 65.349h269.999v269.299h-269.999z"
        fill="currentColor"
        opacity="0.25"
      />
      <path
        d="m207.648 212.978c-15.868 0-28.351 12.716-28.351 28.109v28.85c0 15.189 12.483 28.107 28.351 28.107 1.921 0 3.79-.202 5.573-.53l5.923 17.759h16.612l-7.866-25.561c4.607-5.264 7.866-12.429 7.866-19.775v-28.85c0-15.392-12.175-28.109-28.109-28.109zm144.801 0c-15.911 0-28.118 12.716-28.118 28.109v28.85c0 15.189 12.207 28.107 28.118 28.107 15.88 0 28.109-12.918 28.109-28.107v-28.85c0-15.392-12.228-28.109-28.109-28.109zm-76.391 67.761h39.604v17.302h-56.831v-85.066h17.227zm87.919-10.803c0 5.594-5.202 10.806-11.528 10.806-6.358 0-11.527-5.211-11.527-10.806v-28.85c0-6.358 5.169-11.465 11.527-11.465 6.326 0 11.528 5.106 11.528 11.465zm-144.832 0c0 5.594-5.085 10.806-11.496 10.806-6.358 0-11.484-5.211-11.484-10.806v-28.85c0-6.358 5.126-11.465 11.484-11.465 6.411 0 11.496 5.106 11.496 11.465zm16.617-111.017c0 15.965-12.175 28.278-28.098 28.278-15.889 0-28.362-12.313-28.362-28.278v-56.819h16.867v56.819c0 6.454 5.126 11.656 11.495 11.656 6.401 0 11.486-5.202 11.486-11.656v-56.819h16.612zm125.363 28.274h-17.291v-85.097h17.291zm-85.067-.005h-17.227v-85.097h17.227l22.334 48.677v-48.677h17.27v85.097h-17.27l-22.334-48.753z"
        fill="currentColor"
      />
    </SVGIcon>
  );
}

export default SvgUniqloLogo;
