import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgMoon(props) {
  return (
    <SVGIcon viewBox="0 0 50 50" width="1em" height="1em" {...props}>
      <path
        fill="currentColor"
        d="M17.865 5.572h-1.312v1.313a1.421 1.421 0 01-2.842 0V5.572h-1.312a1.419 1.419 0 110-2.839h1.312V1.421a1.42 1.42 0 112.84 0l.001 1.313h1.313a1.42 1.42 0 110 2.838m-5.152 11.513h-.793v.792a1.42 1.42 0 11-2.841 0v-.792h-.794a1.42 1.42 0 110-2.84h.793v-.795a1.42 1.42 0 012.841 0v.795h.793a1.42 1.42 0 11.001 2.84zm10.156 29.958c-9.031 0-17.255-5.667-20.469-14.104a1.425 1.425 0 01.212-1.382 1.4 1.4 0 011.293-.532c.8.1 1.613.151 2.419.151 10.504 0 19.052-8.548 19.052-19.054 0-2.331-.419-4.61-1.242-6.778a1.429 1.429 0 01.212-1.383 1.41 1.41 0 011.295-.531c10.9 1.378 19.121 10.715 19.121 21.719 0 12.073-9.821 21.894-21.893 21.894zM6 34.014c3.262 6.176 9.779 10.191 16.869 10.191 10.507 0 19.052-8.548 19.052-19.055 0-8.803-6.044-16.382-14.39-18.479.454 1.775.686 3.6.686 5.451 0 12.181-10.06 22.112-22.217 21.892zM43.541 50a1.42 1.42 0 01-1.421-1.421v-1.313h-1.311a1.419 1.419 0 110-2.84h1.311v-1.313a1.42 1.42 0 112.841 0v1.313h1.31a1.42 1.42 0 110 2.84h-1.31v1.313A1.42 1.42 0 0143.541 50z"
      />
    </SVGIcon>
  );
}

export default SvgMoon;
