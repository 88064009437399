import React from "react";
import styled, { css } from "styled-components";
import {
  Block,
  Button,
  FadeIn,
  Grid,
  HeadingStroke,
  Link,
  Paragraph,
  Relative,
  Stack,
} from "components";
import { Content } from "content";
import { HeaderOffset } from "Header";
import { RouterLink } from "router";
import { Skeleton } from "skeleton";
import HERO_IMAGE from "styles/images/bg-gradient-3.jpg";

import { PortfolioScrollRoutes } from "./portfolio.routes";
import { FillImage } from "./FillImage";

const HeroButton = styled(Button)`
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: ${({ variant }) => (variant === "Cta" ? 1 : 0)};

    background-image: url(${HERO_IMAGE});
    background-size: cover;
    background-position: center bottom;
    mix-blend-mode: overlay;
    isolation: isolate;

    transition-property: opacity;
    transition-duration: ${({ theme }) => theme.motion.speed[1]};
    transition-timing-function: ${({ theme }) => theme.motion.spring};
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 1;
    }
  }
  &:active {
    &::after {
      opacity: 0.9;
    }
  }
`;

const ImageOverlay = styled(Block)`
  background: ${({ bg, theme }) => theme.colors.surfaceBase};
  position: absolute;
  top: 0;
  bottom: 0%;
  left: 0;
  right: 0;
  isolation: isolate;

  ${({ theme }) =>
    theme.colorScheme === "dark" &&
    css`
      mix-blend-mode: soft-light;
      filter: contrast(2) brightness(0.75);
    `};
`;
ImageOverlay.displayName = "ImageOverlay";

export const Hero = () => {
  return (
    <HeaderOffset>
      <Relative>
        <Grid
          as="section"
          gridTemplateColumns={["initial", "1fr 1fr", "3fr 2fr"]}
          minHeight="clamp(600px, 75vh, 900px)"
        >
          <React.Suspense fallback={null}>
            <ImageOverlay width="100%" height="100%">
              <FillImage src={HERO_IMAGE} />
            </ImageOverlay>

            <Block id="hero-left" key="left">
              <Block
                alignItems="center"
                display="flex"
                height="100%"
                position="relative"
                zIndex={1}
              >
                <Block pt={[5, 0]} px={[3, 4, 5]}>
                  <FadeIn delay={1}>
                    <HeadingStroke
                      fontFamily="title"
                      fontSize={[11, 11, 9, 11, "12rem"]}
                      color="surfaceCallout"
                    >
                      <Skeleton display="inline-block">UX</Skeleton>
                    </HeadingStroke>
                    <HeadingStroke
                      fontFamily="title"
                      fontSize={[9, 8, 9, 10, "7.5rem"]}
                    >
                      <Skeleton display="inline-block">Developer</Skeleton>
                    </HeadingStroke>
                  </FadeIn>
                </Block>
              </Block>
            </Block>

            <Block
              id="hero-right"
              key="right"
              textAlign="left"
              variant="Primary"
              position="relative"
            >
              <Grid
                height="100%"
                gridTemplateRows="1fr auto"
                alignItems="center"
              >
                <Block py={[0, 5]} px={3}>
                  <Stack gap={4}>
                    <Block pt={4}>
                      <FadeIn delay={2}>
                        <Stack gap={3}>
                          <Skeleton>
                            <Paragraph fontSize={4}>
                              <Content id="portfolio.heroSubtitle" />
                            </Paragraph>
                            <Paragraph fontSize={4} pr={{ large: 6 }}>
                              Currently building the future of commerce and
                              fulfillment as a{" "}
                              <b>
                                Staff Front-end Developer at{" "}
                                <Link
                                  variant="Flat"
                                  as="a"
                                  href="https://shopify.com/fulfillment"
                                >
                                  Shopify
                                </Link>
                              </b>
                              .
                            </Paragraph>
                          </Skeleton>
                        </Stack>
                      </FadeIn>
                    </Block>
                  </Stack>
                </Block>

                <Block>
                  <FadeIn delay={3}>
                    <Grid
                      gridGap={[3, 2]}
                      gridTemplateColumns={[
                        "none",
                        "none",
                        "none",
                        "none",
                        "auto 1fr",
                      ]}
                      overflow="hidden"
                      py={[4, 3]}
                      px={[3, 3]}
                    >
                      <HeroButton
                        forwardedAs={RouterLink}
                        to={{ hash: PortfolioScrollRoutes.Work }}
                        variant="Ghost"
                      >
                        See Work
                      </HeroButton>
                      <HeroButton
                        forwardedAs="a"
                        href="mailto:brendan.rygus@gmail.com"
                        variant="Cta"
                        target="_blank"
                      >
                        Get in touch
                      </HeroButton>
                    </Grid>
                  </FadeIn>
                </Block>
              </Grid>
            </Block>
          </React.Suspense>
        </Grid>
      </Relative>
    </HeaderOffset>
  );
};
