import styled from "styled-components";
import { grid, layout } from "styled-system";
import { Block } from "components";

export const Grid = styled(Block)`
  ${grid};
  ${layout};
  display: grid;
`;
Grid.displayName = "Grid";
