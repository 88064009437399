import React from "react";
import { ThemeProvider as RootThemeProvider } from "styled-components";
import { themes } from "theme";
import { useLocalStorage } from "usies/use-local-storage";

const matchMedia = query =>
  window.matchMedia ? window.matchMedia(query).matches : false;

const THEMES = {
  DARK: "dark",
  LIGHT: "light"
};

export const userPrefersDark = () => matchMedia("(prefers-color-scheme: dark)");

const DarkModeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
  const { value: savedTheme, updateValue } = useLocalStorage("theme");
  const [isDarkMode, setIsDarkMode] = React.useState(() =>
    savedTheme ? savedTheme === THEMES.DARK : userPrefersDark()
  );
  const toggleDarkMode = React.useCallback(() => {
    setIsDarkMode(isDark => !isDark);
    updateValue(isDarkMode ? THEMES.LIGHT : THEMES.DARK);
  }, [updateValue, isDarkMode]);

  return (
    <DarkModeContext.Provider value={[isDarkMode, toggleDarkMode]}>
      <RootThemeProvider theme={isDarkMode ? themes.dark : themes.light}>
        {children}
      </RootThemeProvider>
    </DarkModeContext.Provider>
  );
};
ThemeProvider.displayName = "ThemeProviderWithDarkMode";

export const useDarkMode = () => React.useContext(DarkModeContext);
