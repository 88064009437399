import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgRocheLogo(props) {
  return (
    <SVGIcon viewBox="0 0 744.09448 403.22833" width="1em" {...props}>
      <g fill="currentColor">
        <path d="M497.078 158.856c-4.412-3.244-11.42-5.32-18.167-5.191-7.397.13-14.404 2.466-19.336 6.359v-46.457h-22.968v151.568h22.968v-79.158c0-5.45 4.672-11.55 13.237-11.938 3.763-.13 9.084 1.557 11.809 5.58 2.855 4.152 2.725 9.862 2.725 15.961l-.13 69.555h22.969v-70.334c0-20.762-2.595-28.16-13.107-35.945M310.213 210.893c0 14.793-.52 18.946-.649 20.243-.779 8.305-4.282 17-13.885 17-9.473 0-13.366-9.343-13.756-17.389-.13 0-.648-5.06-.648-19.854s.519-21.023.648-21.023c0-8.435 4.413-16.74 13.756-16.74 9.473 0 13.755 8.305 13.885 16.61 0 .13.649 6.359.649 21.153zm22.19-17.908c-3.244-32.313-21.671-39.32-36.724-39.32-21.412 0-34.389 13.626-36.724 39.32-.26 2.076-.52 5.84-.65 17.908-.129 8.824.52 16.09.65 17.908 1.427 24.006 15.182 38.67 36.724 38.67 21.541 0 35.297-14.923 36.854-38.67.13-1.687.519-9.084.519-17.908 0-4.023-.26-14.794-.649-17.908M548.596 197.267c.13-5.061.13-6.1.26-7.527.649-9.083 4.671-16.61 14.015-16.61 11.03-.13 13.625 10.641 14.014 16.61.26 3.245.39 5.32.39 7.527h-28.679zm28.42 30.366s0 1.687-.26 3.763c-.65 5.32-2.725 16.74-13.885 16.74-9.603 0-13.237-8.695-13.885-17.26-.13-2.465-.52-5.19-.52-14.533h51.259s0-7.657-.13-12.198c0-1.817-.13-4.802-.52-11.16-.778-9.733-4.411-20.374-9.991-27.122-6.359-8.045-15.443-12.198-26.213-12.198-20.763 0-33.74 13.496-36.206 39.32-.26 2.335-.648 5.58-.648 17.908 0 8.824.519 16.22.648 17.908 1.298 23.617 15.313 38.67 36.206 38.67 20.762 0 34.648-14.664 36.205-39.838h-22.06" />
        <path d="M185.636 36.225h373.471l142.745 167.141-142.745 164.805H185.636L42.243 202.847 185.636 36.225zM568.06 17.668H176.552L17.717 202.198 176.682 385.56h391.38l158.316-182.713L568.061 17.668" />
        <path d="M190.308 134.2h10.77c11.81 0 18.297 6.229 18.557 17.389 0 .908.13 1.816.13 3.503 0 2.077 0 2.726-.13 3.634-.649 6.618-3.633 18.167-17.389 18.167h-11.938V134.2zm54.372 121.462l-2.336-37.113c-1.038-16.61-5.32-26.473-14.015-31.534 6.23-3.763 14.275-12.976 14.145-32.312 0-29.327-18.297-39.709-39.839-39.968h-35.037v150.4h22.58l.13-68.776h12.587c7.267 0 15.442 6.358 16.74 24.266l2.336 35.167c.26 5.061 1.168 9.343 1.168 9.343h22.71s-1.04-4.152-1.169-9.473M399.493 226.594c0 1.687-.13 3.115-.26 4.153-.648 8.435-3.503 17.389-13.885 17.389-9.473 0-13.236-9.733-14.014-16.87-.39-4.282-.26-7.656-.39-20.373-.13-14.794.39-18.687.649-21.023.779-9.473 4.412-16.74 13.755-16.74 9.733-.13 13.756 8.305 13.885 16.61 0 .13.26 2.077.39 6.489h22.71c0-1.557-.13-2.855-.13-3.504-2.596-31.404-21.802-39.06-36.855-39.06-21.411 0-34.388 13.626-36.724 39.32-.26 2.076-.519 5.84-.649 17.908-.13 8.824.52 16.09.65 17.908 1.427 24.006 15.182 38.67 36.723 38.67 22.32.13 34.648-13.496 36.854-38.67 0-.909.13-1.558.13-2.207h-22.839" />
      </g>
    </SVGIcon>
  );
}

export default SvgRocheLogo;
