import * as React from "react";

import { SVGIcon } from "./icon.style";

function SvgSun(props) {
  return (
    <SVGIcon viewBox="0 0 50 50" width="1em" height="1em" {...props}>
      <path
        fill="currentColor"
        d="M25 40.123C16.66 40.123 9.877 33.34 9.877 25S16.66 9.877 24.999 9.877c8.339 0 15.124 6.783 15.124 15.123S33.339 40.123 25 40.123m0-33.237a1.42 1.42 0 01-1.421-1.421V1.42a1.42 1.42 0 012.842 0v4.044A1.422 1.422 0 0125 6.886zm-13.813 5.721c-.364 0-.728-.137-1.004-.416L7.321 9.332a1.418 1.418 0 010-2.006 1.416 1.416 0 012.01 0l2.859 2.859a1.418 1.418 0 01-1.003 2.422zM5.465 26.422H1.421a1.419 1.419 0 110-2.84h4.044a1.419 1.419 0 110 2.84zm2.864 16.672a1.418 1.418 0 01-1.004-2.423l2.859-2.859a1.414 1.414 0 012.008 0 1.416 1.416 0 010 2.007l-2.859 2.859a1.413 1.413 0 01-1.004.416zM25.001 50c-.784 0-1.42-.634-1.42-1.42v-4.042a1.42 1.42 0 012.841 0v4.042A1.42 1.42 0 0125.001 50zm16.674-6.908c-.364 0-.727-.137-1.004-.417l-2.859-2.859a1.42 1.42 0 112.009-2.007l2.859 2.859a1.418 1.418 0 010 2.006c-.278.28-.641.418-1.005.418zm6.904-16.673h-4.044a1.419 1.419 0 010-2.84h4.044a1.42 1.42 0 110 2.84zm-9.766-13.813a1.418 1.418 0 01-1.004-2.423l2.859-2.859a1.414 1.414 0 012.008 0 1.418 1.418 0 010 2.006l-2.859 2.859c-.277.279-.64.417-1.004.417zM25 12.717c-6.771 0-12.283 5.512-12.283 12.283S18.228 37.283 25 37.283c6.773 0 12.285-5.511 12.285-12.283S31.773 12.717 25 12.717z"
      />
    </SVGIcon>
  );
}

export default SvgSun;
